import type { AppDownloadBanner as AppDownloadBannerType } from "__generated__/sanity/sanitySchema";
import { Flex } from "@daangn/carotene";
import { KarrotLocalCountryCode } from "@karrotmarket-com/core";
import React from "react";

import { useDisclosure } from "~/hooks/useDisclosure";
import { Position } from "~/hooks/useMoveToApp";
import { useTrackClickButtonMoveToApp } from "~/hooks/useTrackClickButtonMoveToApp";

import { GL_APPSFLYER_LINK, KR_MAIN_FEED_ONELINK } from "../constants";
import useDetectUserAgent from "../hooks/useDetectUserAgent";
import usePrefersColorScheme from "../hooks/usePrefersColorScheme";
import { Link, useRootLoaderData } from "../remix-lib";
import * as css from "./AppDownloadBanner.css";
import GlobalQrModal, { KRQrModal } from "./QrModal";
import SanityImage from "./SanityImage";

export type AppDownloadBannerProps = {
  data: AppDownloadBannerType | null;
};

const AppDownloadBanner: React.FC<AppDownloadBannerProps> = ({ data }) => {
  const rootData = useRootLoaderData();
  const { isOpen, onOpen, setOpenState } = useDisclosure();
  const mode = usePrefersColorScheme();
  const device = useDetectUserAgent();
  const { trackClickButtonMoveToApp } = useTrackClickButtonMoveToApp();

  const images = {
    light: data?.imageLight?.asset?.url ?? "",
    dark: data?.imageDark?.asset?.url ?? "",
  };

  const mobileMainFeedLink =
    rootData.karrotLocalCountryCode === KarrotLocalCountryCode.KR
      ? KR_MAIN_FEED_ONELINK
      : GL_APPSFLYER_LINK.Mobile.Feed;

  const handleClickDownload = () => {
    if (device.isMobile) {
      return;
    }

    trackClickButtonMoveToApp({
      position: Position.AppDownloadBanner,
    });

    onOpen();
  };

  return (
    <Flex justifyContent="center" overflow="hidden" className={css.container}>
      <div className={css.centered}>
        <div className={css.centeredContainer}>
          <div className={css.titles}>
            <div className={css.title1}>{data?.mainTitle}</div>
            <div className={css.title2}>{data?.subTitle}</div>
            <div className={css.appStoreBadges} onClick={handleClickDownload}>
              <Link
                href={device.isMobile && mobileMainFeedLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SanityImage
                  className={css.appStoreBadge}
                  src={data?.appStoreBadgeImageUrl?.asset?.url ?? ""}
                  alt="Download on the App Store"
                  loading="lazy"
                />
              </Link>
              <Link
                href={device.isMobile && mobileMainFeedLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SanityImage
                  className={css.appStoreBadge}
                  src={data?.playStoreBadgeImageUrl?.asset?.url ?? ""}
                  alt="Get it on Google Play"
                  loading="lazy"
                />
              </Link>
            </div>
            {rootData.karrotLocalCountryCode === KarrotLocalCountryCode.KR ? (
              <KRQrModal
                open={isOpen}
                onOpenChange={setOpenState}
                qrLink={KR_MAIN_FEED_ONELINK}
              />
            ) : (
              <GlobalQrModal
                open={isOpen}
                onOpenChange={setOpenState}
                qrLink={GL_APPSFLYER_LINK.Web.Feed}
              />
            )}
          </div>
          {mode && (
            <div className={css.imageLayout}>
              <SanityImage
                src={images[mode]}
                alt=""
                className={css.image}
                loading="lazy"
              />
            </div>
          )}
        </div>
      </div>
    </Flex>
  );
};

export default AppDownloadBanner;
