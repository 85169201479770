import IconChevronDownLine from "@daangn/react-monochrome-icon/IconChevronDownLine";
import { Slot } from "@radix-ui/react-slot";
import clsx from "clsx";
import * as React from "react";

import { fieldButton } from "./style";

export interface FieldButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode;
}

export const FieldButton = React.forwardRef<
  HTMLButtonElement,
  FieldButtonProps
>(
  (
    { className, children, icon = <IconChevronDownLine />, ...otherProps },
    ref,
  ) => {
    const classNames = fieldButton();
    return (
      <button
        ref={ref}
        className={clsx(classNames.root, className)}
        {...otherProps}
      >
        <div>{children}</div>
        {icon && (
          <Slot className={classNames.icon} aria-hidden="true">
            {icon}
          </Slot>
        )}
      </button>
    );
  },
);
FieldButton.displayName = "FieldButton";
