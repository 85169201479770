import { Text } from "@daangn/carotene";
import clsx from "clsx";

import { useLocale } from "./remix-lib";
import * as css from "./SkipLink.css";

interface Props {
  href: string;
  className?: string;
}

export function SkipLink({ href, className }: Props) {
  const { t } = useLocale();
  return (
    <Text weight="strong" size="medium" asChild color="neutralInverted">
      <a href={href} className={clsx(css.link, className)}>
        {t("SkipLink.label")}
      </a>
    </Text>
  );
}
