import * as css from "./fieldButton.css";

export const fieldButton = () => {
  const { root, icon } = css;

  return {
    root: root,
    icon: icon,
  };
};
