import { Box, Flex, Text, VisuallyHidden } from "@daangn/carotene";
import IconXmarkLine from "@daangn/react-monochrome-icon/IconXmarkLine";
import {
  KarrotLocalCountryCode,
  isKarrotLocalCountryCode,
} from "@karrotmarket-com/core";
import { useState } from "react";

import {
  FieldButton,
  FieldButtonIcon,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/design-system/components";
import { useRootLoaderData } from "~/remix-lib";

import * as css from "./CountrySelectBanner.css";
import PageMaxWidthWrapper from "./PageMaxWidthWrapper";

const countryCodeMap = {
  [KarrotLocalCountryCode.CA]: {
    name: "Canada(English)",
    text: "It looks like you’re visiting from another country. Would you like to update your location to see local content?",
    buttonText: "Confirm",
  },
  [KarrotLocalCountryCode.US]: {
    name: "United States",
    text: "It looks like you’re visiting from another country. Would you like to update your location to see local content?",
    buttonText: "Confirm",
  },
  [KarrotLocalCountryCode.JP]: {
    name: "日本",
    text: "現在いらっしゃる国のコンテンツを閲覧するには、現在地の国に変更してください。",
    buttonText: "決定",
  },
  [KarrotLocalCountryCode.KR]: {
    name: "대한민국",
    text: "현재 위치하신 국가의 콘텐츠를 확인하시려면 현재 국가를 선택해주세요.",
    buttonText: "확인",
  },
  [KarrotLocalCountryCode.UK]: {
    name: "United Kingdom",
    text: "It looks like you’re visiting from another country. Would you like to update your location to see local content?",
    buttonText: "Confirm",
  },
};

type CountrySelectBannerProps = {
  onBannerClose: () => void;
  initKarrotLocalCountryCode: KarrotLocalCountryCode;
};
const CountrySelectBanner = (props: CountrySelectBannerProps) => {
  const rootData = useRootLoaderData();
  const [country, setCountry] = useState<KarrotLocalCountryCode>(
    props.initKarrotLocalCountryCode,
  );
  const [close, setClose] = useState(false);

  const selectItems = Object.entries(countryCodeMap)
    .filter(([c]) => c !== rootData.karrotLocalCountryCode)
    .map(([countryCode, countryInfo]) => (
      <SelectItem
        key={countryCode}
        value={countryCode}
        className={css.selectItem}
      >
        {countryInfo.name}
      </SelectItem>
    ));

  const onCountryChange = () => {
    window.location.href = `/${country}/`;
  };

  return (
    <>
      {!close ? (
        <Flex id="container" width="full" className={css.wrapper}>
          <PageMaxWidthWrapper className={css.wrapper}>
            <Flex
              id="wrapper"
              width="full"
              justifyContent="flexStart"
              paddingY={3}
              position="relative"
            >
              <Flex
                id="content"
                flexDirection={{
                  base: "column",
                  medium: "row",
                }}
                width={{
                  medium: "full",
                }}
                gap={{
                  base: 3,
                  medium: 24,
                }}
              >
                <Text size="small" color="neutral" className={css.text}>
                  {countryCodeMap[country].text}
                </Text>
                <Flex
                  id="select"
                  width="full"
                  alignItems={{
                    medium: "center",
                  }}
                  gap={3}
                >
                  <Select
                    defaultValue={country}
                    onValueChange={(v) => {
                      if (isKarrotLocalCountryCode(v)) {
                        setCountry(v);
                      }
                    }}
                  >
                    <SelectTrigger asChild>
                      <FieldButton
                        className={css.trigger}
                        icon={
                          <FieldButtonIcon size={16} color="neutralMuted" />
                        }
                        aria-label={"국가 변경"}
                      >
                        <SelectValue>
                          {countryCodeMap[country].name}
                        </SelectValue>
                      </FieldButton>
                    </SelectTrigger>
                    <SelectContent
                      className={css.selectViewport}
                      position="item-aligned"
                    >
                      {selectItems}
                    </SelectContent>
                  </Select>
                  <button
                    type="button"
                    className={css.confirmButton}
                    onClick={onCountryChange}
                  >
                    {countryCodeMap[country].buttonText}
                  </button>
                  <Box
                    display={{
                      base: "none",
                      medium: "inlineFlex",
                    }}
                    marginLeft={7}
                    onClick={() => {
                      props.onBannerClose();
                      setClose(true);
                    }}
                  >
                    <CloseButton />
                  </Box>
                </Flex>
              </Flex>
              <Box
                display={{
                  base: "inlineFlex",
                  medium: "none",
                }}
                onClick={() => {
                  props.onBannerClose();
                  setClose(true);
                }}
              >
                <CloseButton />
              </Box>
            </Flex>
          </PageMaxWidthWrapper>
        </Flex>
      ) : null}
    </>
  );
};

const CloseButton = () => {
  return (
    <Flex
      as="button"
      justifyContent="center"
      alignItems="center"
      className={css.closeButton}
    >
      <VisuallyHidden>close</VisuallyHidden>
      <IconXmarkLine size="16" aria-hidden />
    </Flex>
  );
};

export default CountrySelectBanner;
