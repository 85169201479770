import { useEffect, useState } from "react";

import { useHydrated } from "~/hooks/useHydrated";

const SCROLL_Y_THRESHOLD = 104;

interface Params {
  isDetailPage: boolean;
}

export function useDetailPageGNB({ isDetailPage }: Params) {
  const [gnbState, setGnbState] = useState<"open" | "closed" | "init">("init");
  const hydrated = useHydrated();

  useEffect(() => {
    if (isDetailPage && hydrated) {
      setGnbState(window.scrollY < SCROLL_Y_THRESHOLD ? "init" : "closed");
    }
  }, [isDetailPage, hydrated]);

  useEffect(() => {
    let previousScrollY = window.scrollY;

    const onScroll = () => {
      const currentScrollY = window.scrollY;
      if (previousScrollY === currentScrollY) {
        return;
      }

      const direction = previousScrollY < currentScrollY ? "down" : "up";
      previousScrollY = currentScrollY;

      if (direction === "down" && currentScrollY > SCROLL_Y_THRESHOLD) {
        setGnbState("closed");
        return;
      }

      if (direction === "up" && gnbState !== "init") {
        setGnbState("open");
        return;
      }
    };

    if (isDetailPage) {
      window.addEventListener("scroll", onScroll);
    }
    return () => {
      if (isDetailPage) {
        window.removeEventListener("scroll", onScroll);
      }
    };
  }, [isDetailPage, gnbState]);

  return gnbState;
}
