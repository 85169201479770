import { Box, Divider, Flex, Text } from "@daangn/carotene";
import { atoms } from "@daangn/carotene/css";
import IconArrowUpRightFill from "@daangn/react-monochrome-icon/IconArrowUpRightFill";
import type { Footer as FooterType } from "__generated__/sanity/sanitySchema";
import { isEmpty, isNil } from "lodash";
import React from "react";

import Facebook from "~/assets/Facebook";
import Instagram from "~/assets/Instagram";
import Line from "~/assets/Line";
import Note from "~/assets/Note";
import Twitter from "~/assets/Twitter";
import Youtube from "~/assets/Youtube";
import { Link } from "~/remix-lib";
import { GtmVariableName } from "~/utils/GtmVariable";

import { ExternalLink } from "./ExternalLink";
import * as css from "./KRFooter.css";
import KarrotLogo from "./KarrotLogo";
import PageMaxWidthWrapper from "./PageMaxWidthWrapper";

const informationRenderGroupTitle = [
  ["대표", "사업자번호"],
  ["직업정보제공사업 신고번호"],
  ["통신판매업 신고번호"],
  ["호스팅 사업자"],
  ["주소"],
  ["전화", "고객문의"],
];

export type Props = {
  data: FooterType | null;
};

const KRFooter = ({ data }: Props) => {
  return (
    <footer>
      <PageMaxWidthWrapper>
        <Flex
          flexDirection="column"
          backgroundColor="layerDefault"
          color="neutral"
          paddingY={16}
        >
          <Flex
            flexDirection={{
              base: "column",
              medium: "row",
            }}
          >
            <Flex
              flexDirection="column"
              marginBottom={10}
              className={css.flex1}
            >
              <Box marginBottom={6}>
                <KarrotLogo location="footer" />
              </Box>
              <Flex>
                {data?.FooterSocialSection?.map((socialButton, l) => (
                  <Link
                    data-gtm={GtmVariableName.FooterSocial}
                    className={css.socialLink}
                    href={socialButton?.SocialLink ?? "/"}
                    aria-label={`Go to Karrot ${socialButton?.SocialName}`}
                    key={socialButton?.SocialLink || l}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {(() => {
                      switch (socialButton?.SocialName) {
                        case "facebook":
                          return <Facebook />;
                        case "instagram":
                          return <Instagram />;
                        case "youtube":
                          return <Youtube />;
                        case "note":
                          return <Note width={24} height={24} />;
                        case "line":
                          return <Line />;
                        case "twitter":
                          return <Twitter style={{ padding: 3 }} />;
                      }
                    })()}
                  </Link>
                ))}
              </Flex>
            </Flex>

            <div className={css.navSections}>
              {data?.FooterNavSections?.map((navSection, i) => (
                <div key={navSection?.NavLinksSectionTitle || i}>
                  <Box marginBottom={3}>
                    <Text size="medium" className={css.bold}>
                      {navSection?.NavLinksSectionTitle}
                    </Text>
                  </Box>
                  <Flex flexDirection="column">
                    {navSection?.NavLink?.map((link, j) => {
                      if (link?.NavLinkExternal) {
                        return (
                          <Text
                            size="small"
                            asChild
                            key={link.NavLinkLink ?? j}
                            color="neutral"
                          >
                            <ExternalLink
                              data-gtm={GtmVariableName.FooterNavigation}
                              className={css.navSectionLink}
                              href={link?.NavLinkLink ?? "/"}
                            >
                              {link?.NavLinkLabel}
                              {link?.SuffixArrowIcon && (
                                <IconArrowUpRightFill
                                  className={atoms({
                                    marginLeft: 0.5,
                                  })}
                                  size={12}
                                />
                              )}
                            </ExternalLink>
                          </Text>
                        );
                      }

                      return (
                        <Text
                          size="small"
                          asChild
                          key={link?.NavLinkLink ?? j}
                          color="neutral"
                        >
                          <Link
                            data-gtm={GtmVariableName.FooterNavigation}
                            className={css.navSectionLink}
                            href={link?.NavLinkLink ?? "/"}
                            key={link?.NavLinkLink ?? j}
                            target="_self"
                          >
                            {link?.NavLinkLabel}
                          </Link>
                        </Text>
                      );
                    })}
                  </Flex>
                </div>
              ))}
            </div>
          </Flex>

          <Divider
            className={atoms({
              marginY: 10,
            })}
          />

          <Flex flexDirection="column">
            <Text size="small" color="neutralSubtle" className={css.bold}>
              (주) 당근마켓
            </Text>
            <Flex
              flexDirection="row"
              flexWrap="wrap"
              className={css.companyInfoWrapper}
            >
              {informationRenderGroupTitle.map((informationTitle) =>
                informationTitle.map((title, index) => {
                  const info = data?.FooterCompanyInfoSection?.find(
                    (footerCompanyInfo) =>
                      footerCompanyInfo?.CompanyInfoTitle === title,
                  );

                  const isFirstElement = index === 0;

                  return isNil(info) ? null : (
                    <React.Fragment key={title}>
                      {isFirstElement ? null : (
                        <Flex alignItems="center">
                          <Box
                            marginX={2}
                            height={2.5}
                            className={css.infoDivider}
                          />
                        </Flex>
                      )}
                      <Flex gap={1}>
                        <Text
                          size="small"
                          color="neutralSubtle"
                          className={css.bold}
                        >
                          {info.CompanyInfoTitle}
                        </Text>
                        {isEmpty(info.CompanyInfoContent) ? null : (
                          <Text size="small" color="neutralSubtle">
                            {info.CompanyInfoContent}
                          </Text>
                        )}
                      </Flex>
                    </React.Fragment>
                  );
                }),
              )}
            </Flex>

            <Flex
              flexDirection="row"
              flexWrap="wrap"
              marginTop={6}
              className={css.bottomGap}
            >
              {data?.FooterBottomSection?.map((contact, i) => {
                if (!contact?.NavLinkLink) {
                  return null;
                }

                return (
                  <Text
                    size="small"
                    color="neutralSubtle"
                    weight="strong"
                    className={
                      contact.NavLinkLabel === "개인정보처리방침"
                        ? css.bold
                        : undefined
                    }
                    asChild
                    key={contact.NavLinkLink}
                  >
                    <ExternalLink href={contact.NavLinkLink}>
                      {contact?.NavLinkLabel}
                    </ExternalLink>
                  </Text>
                );
              })}
            </Flex>
          </Flex>
        </Flex>
      </PageMaxWidthWrapper>
    </footer>
  );
};

export default KRFooter;
