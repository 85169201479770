const options = {
  enableHighAccuracy: true,
  maximumAge: 30000,
  timeout: 27000,
};

export function getCurrentPosition() {
  if (!navigator.geolocation) {
    throw new Error("Geolocation service IS NOT available.");
  }

  return new Promise<GeolocationPosition>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
}
