import { Icon } from "@daangn/carotene";
import type { vars } from "@daangn/carotene/vars";
import IconChevronDownLine from "@daangn/react-monochrome-icon/IconChevronDownLine";

type Props = {
  /**
   * @default 20
   */
  size?: number;
  /**
   * @default neutralMuted
   */
  color?: keyof typeof vars.color.fg;
  className?: string;
};

export function FieldButtonIcon({
  size = 20,
  color = "neutralMuted",
  className,
}: Props) {
  return (
    <Icon
      svg={
        <IconChevronDownLine className={className} color={color} size={size} />
      }
      aria-hidden
    />
  );
}
