import type { KarrotLocalCountryCode } from "@karrotmarket-com/core";
import { isKarrotLocalCountryCode } from "@karrotmarket-com/core";
import { to } from "await-to-js";
import { debounce, groupBy, omit, pick } from "lodash";
import { useCallback, useDeferredValue, useMemo, useState } from "react";
import type { Location as HoianLocation } from "services";

import { useRootLoaderData } from "~/remix-lib/useRootLoaderData";
import { getCurrentPosition } from "~/utils";

type Location = {
  id: number;
  name: string;
  name1: string;
  name2: string;
  name3: string;
  depth: number;
};

type AllCountriesLocations = {
  karrotLocalCountryCode: KarrotLocalCountryCode;
  locations: Location[];
}[];

type Region = { countryName: KarrotLocalCountryCode } & Location;

type FindAllByRegionName = (
  regionName: string,
) => Promise<{ allRegions: Region[] }>;

type FindLocationsByCoordOrKeyword = (params: {
  karrotLocalCountryCode: KarrotLocalCountryCode;
  keyword?: string;
  coords?: {
    lat: number;
    lng: number;
  };
}) => Promise<{ locations: HoianLocation[] }>;

type UseLocationSelectModalParams = {
  initLocations: HoianLocation[];
  findAllByRegionName: FindAllByRegionName;
  findLocationsByCoordOrKeyword: FindLocationsByCoordOrKeyword;
};

export type LocationError =
  | {
      type: "geolocation";
      error: GeolocationPositionError;
    }
  | {
      type: "locationService";
      error: unknown;
    }
  | null;

export function useLocationSelectModal({
  initLocations,
  findAllByRegionName,
  findLocationsByCoordOrKeyword,
}: UseLocationSelectModalParams) {
  const { karrotLocalCountryCode: currentKarrotLocalCountryCode } =
    useRootLoaderData();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<LocationError>(null);
  const [locationInput, setLocationInput] = useState<string>("");

  const [allCountriesLocations, setAllCountriesLocations] =
    useState<AllCountriesLocations>(() => [
      {
        karrotLocalCountryCode: currentKarrotLocalCountryCode,
        locations: initLocations.map(toLocation.fromHoianLocation),
      },
    ]);
  const deferredAllCountriesLocations = useDeferredValue(allCountriesLocations);

  const resetLocations = useCallback(() => {
    setAllCountriesLocations([
      {
        karrotLocalCountryCode: currentKarrotLocalCountryCode,
        locations: initLocations.map(toLocation.fromHoianLocation),
      },
    ]);
  }, [currentKarrotLocalCountryCode, initLocations]);

  const resetError = () => {
    setError(null);
  };

  const getLocation = useMemo(
    () =>
      debounce(async (value) => {
        const keyword = value.trim();
        if (keyword.length === 0) {
          resetLocations();
          return;
        }

        try {
          const response = await findAllByRegionName(keyword);
          const nextAllCountriesLocations = toAllCountriesLocations(
            response.allRegions,
            currentKarrotLocalCountryCode,
          );
          setLocationInput(keyword);
          setError(null);
          setAllCountriesLocations(nextAllCountriesLocations);
        } catch (err) {
          setError({
            type: "locationService",
            error: err,
          });
        }
      }, 300),
    [currentKarrotLocalCountryCode, findAllByRegionName, resetLocations],
  );

  const onChangeLocationInput = (value: string) => {
    getLocation(value);
  };

  const searchCurrentPosition = async () => {
    setLoading(true);

    const [geolocationErr, geolocationPosition] = await to<
      GeolocationPosition,
      GeolocationPositionError
    >(getCurrentPosition());

    /**
     * 현재 위치 탐색 인지를 위한 의도적 로딩
     */
    await delay(1000);

    if (geolocationErr) {
      setError({
        type: "geolocation",
        error: geolocationErr,
      });
    }

    if (geolocationPosition) {
      const [locationServiceErr, locationService] = await to(
        findLocationsByCoordOrKeyword({
          karrotLocalCountryCode: currentKarrotLocalCountryCode,
          coords: {
            lat: geolocationPosition.coords.latitude,
            lng: geolocationPosition.coords.longitude,
          },
        }),
      );

      if (locationServiceErr) {
        setError({
          type: "locationService",
          error: locationServiceErr,
        });
      }

      if (locationService) {
        setAllCountriesLocations([
          {
            karrotLocalCountryCode: currentKarrotLocalCountryCode,
            locations: locationService.locations.map(
              toLocation.fromHoianLocation,
            ),
          },
        ]);
      }
    }

    setLoading(false);
  };

  return {
    loading,
    error,
    locationInput,
    allCountriesLocations: deferredAllCountriesLocations,
    onChangeLocationInput,
    searchCurrentPosition,
    resetLocations,
    resetError,
  };
}

function prioritizeCurrentCountry(
  countryCodeA: KarrotLocalCountryCode,
  countryCodeB: KarrotLocalCountryCode,
  currentCountryCode: KarrotLocalCountryCode,
) {
  if (countryCodeA === currentCountryCode) {
    return -1;
  }
  if (countryCodeB === currentCountryCode) {
    return 1;
  }
  return 0;
}

function delay(ms: number) {
  return new Promise<void>((resolve) => setTimeout(resolve, ms));
}

export function toAllCountriesLocations(
  allRegions: Region[],
  currentKarrotLocalCountryCode: KarrotLocalCountryCode,
) {
  const regionsGroupByCountry = groupBy(
    allRegions,
    (region) => region.countryName,
  );

  return Object.entries(regionsGroupByCountry)
    .flatMap(([countryName, regions]) => {
      return isKarrotLocalCountryCode(countryName)
        ? [
            {
              karrotLocalCountryCode: countryName,
              locations: regions
                .map(toLocation.fromRegion)
                .filter((region) => 2 <= region.depth && region.depth <= 3),
            },
          ]
        : [];
    })
    .filter(({ locations }) => locations.length > 0)
    .sort((countyWithLocationsA, countyWithLocationsB) =>
      prioritizeCurrentCountry(
        countyWithLocationsA.karrotLocalCountryCode,
        countyWithLocationsB.karrotLocalCountryCode,
        currentKarrotLocalCountryCode,
      ),
    );
}

const toLocation = {
  fromHoianLocation(hoianLocation: HoianLocation): Location {
    return pick(hoianLocation, [
      "id",
      "name",
      "name1",
      "name2",
      "name3",
      "depth",
    ]);
  },
  fromRegion(region: Region): Location {
    return omit(region, "countryName");
  },
};
