import { Box } from "@daangn/carotene";
import { KarrotLocalCountryCode } from "@karrotmarket-com/core";
import type React from "react";

import {
  default as GlobalGlobalNavigationBar,
  SearchForm as GlobalSearchForm,
} from "~/figma-components/GlobalNavigationBar";
import { useRootLoaderData } from "~/remix-lib";
import Footer from "~/services/Footer";
import KRFooter from "~/services/KRFooter";
import { default as KRGlobalNavigationBar } from "~/services/gnb/kr/GlobalNavigationBar";
import { services } from "~/utils/services";

import {
  BrowserConfirmCountryBannerCookieStorage,
  BrowserRegionCookieStorage,
} from "@karrotmarket-com/persistent-storage/client";
import { isNil } from "lodash";
import * as css from "./GlobalLayout.css";
import { SkipLink } from "./SkipLink";
import { useLocationSelectModal } from "./figma-components/useLocationSelectModal";
import AppDownloadBanner from "./services/AppDownloadBanner";
import CountrySelectBanner from "./services/CountrySelectBanner";
import { FixedTopSearchBar } from "./services/gnb/FixedTopSearchBar";
import { LocationSelectContext } from "./services/gnb/LocationSelectContext";
import { SearchForm as KRSearchForm } from "./services/gnb/kr/SearchForm";
import { useDetailPageGNB } from "./services/gnb/useDetailPageGNB";
import {
  globalServiceHomeUrl,
  krServiceHomeUrl,
  serviceNameMap,
} from "./site-urls";
import { makeKeywordLinks } from "./utils/makeKeywordLinks";

type PageType = "index" | "detail" | "error" | "default";
type FeatureFlag = "showAppDownloadBanner" | "showGNB" | "showFooter";

type RootLayoutFeatureFlag = {
  [key in FeatureFlag]?: boolean;
};

const MAIN_CONTENT = "main-content";

const karrotmarketApiService = services.getInstance().karrotmarket;
const countryBannerBrowserStorage =
  BrowserConfirmCountryBannerCookieStorage.of();
const regionBrowserStorage = BrowserRegionCookieStorage.of();

async function findKROnlyByRegionName(regionName: string) {
  const krLocations = await karrotmarketApiService.searchLocation({
    karrotLocalCountryCode: KarrotLocalCountryCode.KR,
    keyword: regionName,
  });

  return {
    allRegions: krLocations.locations.map((location) => ({
      ...location,
      countryName: KarrotLocalCountryCode.KR,
    })),
  };
}

type RootLayoutProps = {
  children: React.ReactNode;
  pageType?: PageType;
  flags?: RootLayoutFeatureFlag;
};
export const RootLayout: React.FC<RootLayoutProps> = ({
  children,
  pageType = "default",
  flags = {},
}) => {
  const rootData = useRootLoaderData();

  const {
    loading: locationSelectModalLoading,
    error: locationSelectModalError,
    locationInput,
    allCountriesLocations,
    onChangeLocationInput,
    searchCurrentPosition,
    resetLocations,
    resetError,
  } = useLocationSelectModal({
    initLocations: rootData.__locationSelectModal.fallbackLocations,
    findAllByRegionName:
      rootData.karrotLocalCountryCode === KarrotLocalCountryCode.KR
        ? findKROnlyByRegionName
        : karrotmarketApiService.findAllRegion,
    findLocationsByCoordOrKeyword: karrotmarketApiService.searchLocation,
  });

  const gnbState = useDetailPageGNB({
    isDetailPage: pageType === "detail",
  });

  const isKR = rootData.karrotLocalCountryCode === KarrotLocalCountryCode.KR;
  const GlobalNavigationBar = isKR
    ? KRGlobalNavigationBar
    : GlobalGlobalNavigationBar;

  const defaultFlags = {
    showAppDownloadBanner: true,
    showGNB: true,
    showFooter: true,
  };

  const mergedFlags = { ...defaultFlags, ...flags };

  const showFixedTopSearchBar = pageType === "default";
  const searchBarSize = pageType === "index" ? "large" : "medium";
  const keywordLinks = makeKeywordLinks({
    pathname: isKR
      ? krServiceHomeUrl[serviceNameMap[rootData.__gnb.data.serviceName]]
      : `/${rootData.karrotLocalCountryCode}${globalServiceHomeUrl.BUY_SELL}`,
    region: rootData.region,
    keywordRecord: rootData.__gnb.data.keywords,
  });

  return (
    <LocationSelectContext.Provider
      value={{
        loading: locationSelectModalLoading,
        error: locationSelectModalError,
        locationInput,
        allCountriesLocations,
        onChangeLocationInput,
        searchCurrentPosition,
        resetLocations,
        resetError,
      }}
    >
      <header className={css.header} data-state={gnbState}>
        <SkipLink href={`#${MAIN_CONTENT}`} className={css.skipLink} />
        {!isNil(rootData.countryCodeByIp) &&
          shouldShowCountrySelectBanner({
            countryCodeByIp: rootData.countryCodeByIp,
            karrotLocalCountryCode: rootData.karrotLocalCountryCode,
            origin: rootData.origin,
            countryConfirmBannerCheck: rootData.countryConfirmBannerCheck,
          }) && (
            <CountrySelectBanner
              onBannerClose={() => {
                countryBannerBrowserStorage.update({
                  disabled: true,
                });
              }}
              initKarrotLocalCountryCode={rootData.countryCodeByIp}
            />
          )}
        {mergedFlags.showGNB ? (
          <GlobalNavigationBar
            data={rootData.__gnb.data}
            query={rootData.__gnb.query}
            showNavigationMenu={pageType !== "index"}
            isDetailPage={pageType === "detail"}
            isIndexPage={pageType === "index"}
          />
        ) : null}
      </header>
      {showFixedTopSearchBar && (
        <FixedTopSearchBar
          region={rootData.region}
          keywordType={rootData.__gnb.data.keywords.type}
          keywords={keywordLinks}
          size={searchBarSize}
          searchForm={
            isKR ? (
              <KRSearchForm
                region={rootData.region}
                query={rootData.__gnb.query}
                size={searchBarSize}
              />
            ) : (
              <GlobalSearchForm
                karrotLocalCountryCode={rootData.karrotLocalCountryCode}
                region={rootData.region}
                query={rootData.__gnb.query}
              />
            )
          }
          onSaveRegionToStorage={regionBrowserStorage.update}
        />
      )}
      <main id={MAIN_CONTENT} className={css.main}>
        {children}
        {mergedFlags.showAppDownloadBanner ? (
          <Box marginTop={{ base: 12, small: 20, medium: 24 }}>
            <AppDownloadBanner data={rootData.__appDownloadBanner.data} />
          </Box>
        ) : null}
      </main>
      {mergedFlags.showFooter &&
        (isKR ? (
          <KRFooter data={rootData.__footer.data} />
        ) : (
          <Footer data={rootData.__footer.data} />
        ))}
    </LocationSelectContext.Provider>
  );
};

function shouldShowCountrySelectBanner({
  countryCodeByIp,
  karrotLocalCountryCode,
  origin,
  countryConfirmBannerCheck,
}: {
  countryCodeByIp: KarrotLocalCountryCode | null;
  karrotLocalCountryCode: KarrotLocalCountryCode;
  origin: string;
  countryConfirmBannerCheck: boolean;
}) {
  // ip를 통해 countryCode를 가져올 때 에러가 나는 등 유효한 countryCode를 가져오지 못했을 경우 렌더링 하지 않습니다.
  if (isNil(countryCodeByIp)) {
    return false;
  }

  // 사용자가 위치한 국가와 서비스되는 국가가 일치할 경우 노출하지 않습니다.
  if (countryCodeByIp === karrotLocalCountryCode) {
    return false;
  }

  /**
   * @Note daangn.com(KR)일 경우 배너를 노출하지 않습니다.
   */
  if (origin.includes("daangn.com")) {
    return false;
  }

  // 이전에 취소 버튼을 눌러 배너를 명시적으로 닫은 경우 노출하지 않습니다.
  if (countryConfirmBannerCheck) {
    return false;
  }

  return true;
}
