import { vars } from "@daangn/carotene/vars";

const Line = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2263_3754)">
        <path
          d="M17.4913 2H6.50875C4.01864 2 2 4.01864 2 6.50875V17.4913C2 19.9814 4.01864 22 6.50875 22H17.4913C19.9814 22 22 19.9814 22 17.4913V6.50875C22 4.01864 19.9814 2 17.4913 2Z"
          fill={vars.color.fg.neutral}
        />
        <path
          d="M18.6663 11.0575C18.6663 8.07374 15.6751 5.64624 11.9982 5.64624C8.32133 5.64624 5.33008 8.07374 5.33008 11.0575C5.33008 13.7325 7.70258 15.9725 10.907 16.3962C11.1238 16.4431 11.4195 16.5394 11.4945 16.725C11.562 16.8937 11.5382 17.1575 11.5163 17.3281C11.5163 17.3281 11.4382 17.7987 11.4213 17.8987C11.392 18.0675 11.2876 18.5581 11.9988 18.2581C12.7107 17.9581 15.8388 15.9969 17.2376 14.3869C18.2038 13.3269 18.667 12.2519 18.667 11.0575H18.6663Z"
          fill={vars.color.bg.layerDefault}
        />
        <path
          d="M16.4479 12.7806H14.5748C14.5041 12.7806 14.4473 12.7237 14.4473 12.6531V12.6512V9.74623V9.7431C14.4473 9.67248 14.5041 9.6156 14.5748 9.6156H16.4479C16.5179 9.6156 16.5754 9.6731 16.5754 9.7431V10.2162C16.5754 10.2869 16.5185 10.3437 16.4479 10.3437H15.1748V10.835H16.4479C16.5179 10.835 16.5754 10.8925 16.5754 10.9625V11.4356C16.5754 11.5062 16.5185 11.5631 16.4479 11.5631H15.1748V12.0544H16.4479C16.5179 12.0544 16.5754 12.1119 16.5754 12.1819V12.655C16.5754 12.7256 16.5185 12.7825 16.4479 12.7825V12.7806Z"
          fill={vars.color.fg.neutral}
        />
        <path
          d="M9.51834 12.7806C9.58834 12.7806 9.64584 12.7237 9.64584 12.6531V12.18C9.64584 12.11 9.58834 12.0525 9.51834 12.0525H8.24521V9.74249C8.24521 9.67249 8.18771 9.61499 8.11771 9.61499H7.64459C7.57396 9.61499 7.51709 9.67187 7.51709 9.74249V12.6506V12.6531C7.51709 12.7237 7.57396 12.7806 7.64459 12.7806H9.51771H9.51834Z"
          fill={vars.color.fg.neutral}
        />
        <path
          d="M10.6459 9.6156H10.1734C10.103 9.6156 10.0459 9.67268 10.0459 9.7431V12.6531C10.0459 12.7235 10.103 12.7806 10.1734 12.7806H10.6459C10.7163 12.7806 10.7734 12.7235 10.7734 12.6531V9.7431C10.7734 9.67268 10.7163 9.6156 10.6459 9.6156Z"
          fill={vars.color.fg.neutral}
        />
        <path
          d="M13.8654 9.6156H13.3923C13.3216 9.6156 13.2648 9.67248 13.2648 9.7431V11.4712L11.9335 9.6731C11.9304 9.66873 11.9266 9.66435 11.9235 9.65998H11.9229C11.9204 9.65623 11.9179 9.65373 11.9154 9.65123L11.9129 9.64935C11.9129 9.64935 11.9085 9.6456 11.906 9.64373C11.9048 9.6431 11.9035 9.64185 11.9023 9.64123C11.9004 9.63935 11.8979 9.6381 11.8954 9.63685C11.8941 9.63623 11.8929 9.63498 11.8916 9.63435C11.8891 9.6331 11.8873 9.63185 11.8848 9.6306C11.8835 9.62998 11.8823 9.62935 11.881 9.62873C11.8785 9.62748 11.876 9.62623 11.8735 9.6256L11.8691 9.62435C11.8691 9.62435 11.8641 9.62248 11.8616 9.62185C11.8604 9.62185 11.8585 9.62123 11.8573 9.6206C11.8548 9.6206 11.8523 9.61935 11.8498 9.61873C11.8479 9.61873 11.846 9.61873 11.8441 9.6181C11.8416 9.6181 11.8398 9.61748 11.8373 9.61748C11.8348 9.61748 11.8329 9.61748 11.8304 9.61748C11.8291 9.61748 11.8273 9.61748 11.826 9.61748H11.356C11.286 9.61748 11.2285 9.67435 11.2285 9.74498V12.655C11.2285 12.725 11.2854 12.7825 11.356 12.7825H11.8291C11.8998 12.7825 11.9566 12.7256 11.9566 12.655V10.9269L13.2898 12.7275C13.2991 12.7406 13.3104 12.7512 13.3229 12.7594L13.3241 12.7606C13.3241 12.7606 13.3291 12.7637 13.3323 12.7656C13.3335 12.7662 13.3348 12.7669 13.336 12.7675C13.3379 12.7687 13.3404 12.7694 13.3423 12.7706C13.3441 12.7719 13.3466 12.7725 13.3485 12.7731C13.3498 12.7731 13.351 12.7744 13.3523 12.7744C13.3554 12.7756 13.3579 12.7762 13.361 12.7769H13.3629C13.3735 12.78 13.3848 12.7819 13.396 12.7819H13.866C13.936 12.7819 13.9935 12.725 13.9935 12.6544V9.74435C13.9935 9.67435 13.9366 9.61685 13.866 9.61685L13.8654 9.6156Z"
          fill={vars.color.fg.neutral}
        />
      </g>
      <defs>
        <clipPath id="clip0_2263_3754">
          <rect
            width="20"
            height="20"
            fill={vars.color.bg.layerDefault}
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Line;
