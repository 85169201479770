import { breakpoints } from "@daangn/carotene/css";
import queryString from "query-string";

import {
  SANITY_CDN_BASE_URL,
  SANITY_CDN_CF_PROXY_BASE_URL,
} from "../constants";

const imageWidthMap = {
  small: breakpoints.small,
  medium: breakpoints.medium,
  large: breakpoints.large,
} as const;

const imageQualityMap = {
  small: 82,
  medium: 95,
  large: 100,
} as const;

type BreakpointForSrcSet = "small" | "medium" | "large";

// TODO: avif, webp(fallback) 포맷으로 변경
/**
 * @see https://www.sanity.io/docs/image-urls
 */
export const makeSanityImageSrcSet = (
  sanityImageUrl: string,
  breakpoint: BreakpointForSrcSet,
  options?: {
    w?: string;
    h?: string;
    q?: string;
    useCloudFrontProxy?: boolean;
  },
) => {
  const url = new URL(sanityImageUrl);
  const params = {
    h: options?.h,
    w: options?.w ?? imageWidthMap[breakpoint].toString(),
    q: options?.q ?? imageQualityMap[breakpoint].toString(),
    fm: "webp",
  };

  const stringified = queryString.stringify(params, {
    skipEmptyString: true,
    skipNull: true,
  });

  url.search = stringified;

  return options?.useCloudFrontProxy
    ? replaceToCFProxyURL(url.toString())
    : url.toString();
};

export const replaceToCFProxyURL = (src: string) =>
  src.replace(SANITY_CDN_BASE_URL, SANITY_CDN_CF_PROXY_BASE_URL);
