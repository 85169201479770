import type { KarrotLocalCountryCode } from "@karrotmarket-com/core";
import { clientBuilder } from "@karrotmarket-com/http-client-builder/browser";
import type { Location, RegionFilterOptions } from "services";

const client = clientBuilder.withoutBaseURL().invoke();

const createServices = () => ({
  karrotmarket: {
    searchLocation: async ({
      karrotLocalCountryCode,
      keyword,
      coords,
    }: {
      karrotLocalCountryCode: KarrotLocalCountryCode;
      keyword?: string;
      coords?: {
        lat: number;
        lng: number;
      };
    }): Promise<{ locations: Location[] }> => {
      return client.get({
        url: `/v1/api/search/${karrotLocalCountryCode}/location`,
        queryObject: {
          keyword,
          ...(coords ?? {}),
        },
      });
    },
    getRegionFilterOptions: async ({
      regionId,
      karrotLocalCountryCode,
    }: {
      regionId: string;
      karrotLocalCountryCode: KarrotLocalCountryCode;
    }): Promise<{ regionFilterOptions: RegionFilterOptions }> => {
      return client.get({
        url: `/v1/api/filter/${karrotLocalCountryCode}/region/${regionId}`,
      });
    },
    findAllRegion: async (
      keyword: string,
    ): Promise<{
      allRegions: {
        id: number;
        depth: number;
        name: string;
        name1: string;
        name2: string;
        name3: string;
        countryName: KarrotLocalCountryCode;
      }[];
    }> => {
      return client.get({ url: `/v1/api/search/all-locations/${keyword}` });
    },
  },
});

export const services = (() => {
  let cachedServices: ReturnType<typeof createServices> | null = null;

  return {
    getInstance: () => {
      if (cachedServices) {
        return cachedServices;
      }

      cachedServices = createServices();
      return cachedServices;
    },
  };
})();
