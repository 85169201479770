const Facebook = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5969 12.1465C22.5969 6.31998 17.7192 1.59967 11.6984 1.59967C5.67766 1.59967 0.800003 6.31998 0.800003 12.1465C0.800003 17.4106 4.78641 21.7747 9.99344 22.5669V15.1934H7.22282V12.1465H9.99344V9.82155C9.99344 7.1778 11.6209 5.71998 14.1106 5.71998C15.3022 5.71998 16.5519 5.92623 16.5519 5.92623V8.51842H15.1763C13.8248 8.51842 13.3986 9.32936 13.3986 10.1637V12.1419H16.4211L15.9367 15.1887H13.3986V22.5575C18.6105 21.77 22.5969 17.4106 22.5969 12.1465Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Facebook
