import type { SVGProps } from "react";
import { vars } from "@daangn/carotene/vars";

const Note = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={500}
    height={500}
    fill="none"
    viewBox="0 0 500 500"
    {...props}
  >
    <path
      fill={vars.color.palette.gray900}
      d="M105.251 215.151c-14.707-.409-35.298.408-50.251.408v76.48h21.408v-57.85s19.038-.654 25.902.163c6.455.736 8.825 4.74 9.07 13.074.163 9.478 0 14.463 0 44.613h21.408s.245-34.481 0-46.492c-.327-21.327-7.763-29.906-27.537-30.396ZM204.283 214.906c-21.162 0-38.403 17.322-38.403 38.648 0 21.327 17.241 38.649 38.403 38.649 21.163 0 38.404-17.322 38.404-38.649 0-21.326-17.241-38.648-38.404-38.648Zm0 58.831c-11.112 0-20.1-9.07-20.1-20.264 0-11.195 8.988-20.264 20.1-20.264 11.113 0 20.101 9.069 20.101 20.264 0 11.276-8.988 20.264-20.101 20.264ZM445 253.554c0-21.326-17.241-38.648-38.404-38.648-21.162 0-38.403 17.322-38.403 38.648 0 12.502 5.72 23.206 14.626 30.315 6.21 4.902 14.871 8.334 26.474 8.334 5.638 0 20.264-1.552 30.804-13.237l-8.661-11.603c-3.677 3.269-12.91 7.844-20.264 7.844-9.315 0-14.953-1.797-19.038-5.638-2.533-2.287-4.331-5.801-5.148-10.131h57.442c.327-1.962.572-3.841.572-5.884Zm-57.85-7.027c2.124-9.069 8.497-15.77 19.365-15.77 11.357 0 17.322 7.191 19.283 15.77H387.15ZM315.408 207.797H295.39v21.49h-20.591v18.793h20.591v22.307h20.018V248.08h20.591v-18.793h-20.591v-21.49Z"
    />
  </svg>
);

export default Note;
