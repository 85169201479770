const Youtube = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0.999512C5.92487 0.999512 1 5.92438 1 11.9995C1 18.0746 5.92487 22.9995 12 22.9995C18.0751 22.9995 23 18.0746 23 11.9995C23 5.92438 18.0751 0.999512 12 0.999512ZM18.7054 7.75759C18.9341 7.97573 19.0999 8.24647 19.1868 8.54362C19.4039 9.68587 19.5085 10.845 19.4995 12.0058C19.5077 13.1622 19.403 14.3169 19.1868 15.4548C19.0999 15.752 18.9341 16.0227 18.7054 16.2409C18.4767 16.459 18.1929 16.6172 17.8814 16.7001C16.6997 16.9995 12.0067 16.9995 12.0067 16.9995C12.0067 16.9995 7.29993 16.9995 6.13199 16.7001C5.82048 16.6172 5.53666 16.459 5.30797 16.2409C5.07927 16.0227 4.91345 15.752 4.82651 15.4548C4.60573 14.3173 4.49646 13.1626 4.50013 12.0058C4.49561 10.8445 4.60487 9.68546 4.82651 8.54362C4.91345 8.24647 5.07927 7.97573 5.30797 7.75759C5.53666 7.53944 5.82048 7.38125 6.13199 7.29832C7.31306 6.99356 12.0067 6.99952 12.0067 6.99952C12.0067 6.99952 16.7122 6.99952 17.8814 7.29832C18.1929 7.38125 18.4767 7.53944 18.7054 7.75759ZM10.5049 9.85751V14.1475L14.4213 12.0058L10.5049 9.85751Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Youtube
