import { breakpoints } from "@daangn/carotene/css";
import { isNil } from "lodash";
import React from "react";

import {
  makeSanityImageSrcSet,
  replaceToCFProxyURL,
} from "~/utils/sanityImage";

const getFileExtension = (url: string): string | null => {
  const parsedUrl = new URL(url);
  const pathname = parsedUrl.pathname;
  const match = pathname.match(/\.([a-z0-9]+)(?:[?#]|$)/i);

  if (match) {
    return match[1];
  }

  return null;
};

const getImageAttributes = (src: string, extension: string | null) => {
  if (!extension) {
    return null;
  }

  if (extension === "svg") {
    return {
      src: replaceToCFProxyURL(src),
    };
  }

  return {
    src: replaceToCFProxyURL(src),
    srcSet: `${makeSanityImageSrcSet(src, "small", {
      useCloudFrontProxy: true,
    })} ${breakpoints.small}w, ${makeSanityImageSrcSet(src, "medium", {
      useCloudFrontProxy: true,
    })} ${breakpoints.medium}w, ${makeSanityImageSrcSet(src, "large", {
      useCloudFrontProxy: true,
    })} ${breakpoints.large}w`,
    sizes: `(min-width:${breakpoints.large}px) ${breakpoints.large}px, (min-width:${breakpoints.medium}px) ${breakpoints.medium}px, 100vw`,
  };
};

const SanityImage = React.forwardRef<
  HTMLImageElement,
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
>((props, ref) => {
  if (!props.src) return null;

  const fileExtension = getFileExtension(props.src);
  const imageAttributes = getImageAttributes(props.src, fileExtension);

  if (isNil(imageAttributes)) {
    return null;
  }

  return (
    <img
      {...props}
      src={imageAttributes.src}
      srcSet={imageAttributes.srcSet}
      sizes={imageAttributes.sizes}
      ref={ref}
      alt={props.alt ?? ""}
    />
  );
});

export default SanityImage;
